import axiosClient from "@/api/axios";
import { ref } from "vue";

const productsFormats = ref<any>([]);
const productCategories = ref<any>([]);

export const useHoopay = () => {
  function listProductsFormats() {
    if (!productsFormats.value.length) {
      axiosClient.get('/static/products/formats').then((resp) => {
        let result = resp.data;

        if (result.length > 0) {
          productsFormats.value = result;

          // document.cookie = `productsFormats=${JSON.stringify(
          //   result
          // )}; path=/; domain=${self.BASE_URL_COOKIE}; max-age=432000;`;
        }
        console.log(productsFormats.value);
      }).catch((error: any) => {
        console.error(`Erro ao listar formatos:`, error);
      });
    }
  }

  function listProductCategories(organizationId: number) {
    axiosClient.get(`/organizations/${organizationId}/categories`).then((resp) => {
      let result = resp.data;

      if (result.length > 0) {
        productCategories.value = result;

        // document.cookie = `productCategories=${JSON.stringify(
        //   result
        // )}; path=/; domain=${self.BASE_URL_COOKIE}; max-age=432000;`;
      }
    }).catch((error: any) => {
      console.error(`Erro ao listar categorias:`, error);
    });
  }

  return {
    productsFormats,
    listProductsFormats,
    productCategories,
    listProductCategories
  };
};
