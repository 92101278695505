export enum PaymentMethodEnum {
    CREDIT_CARD = 1,
    DEBIT_CARD,
    TRANSFER,
    DEPOSIT,
    CHECKOUT_ABANDONED,
    BILLET,
    PIX,
    BALANCE,
  }
  
  interface PaymentMethodDetails {
    name: string;
    tag: string;
  }
  
export const PaymentMethodDetails: { [key in PaymentMethodEnum]: PaymentMethodDetails } = {
    [PaymentMethodEnum.CREDIT_CARD]: {
      name: "Cartão de Crédito",
      tag: "credit_card",
    },
    [PaymentMethodEnum.DEBIT_CARD]: {
      name: "Cartão de Débito",
      tag: "debit_card",
    },
    [PaymentMethodEnum.TRANSFER]: {
      name: "Transferência",
      tag: "transfer",
    },
    [PaymentMethodEnum.DEPOSIT]: {
      name: "Depósito",
      tag: "deposit",
    },
    [PaymentMethodEnum.CHECKOUT_ABANDONED]: {
      name: "Checkout abandonado",
      tag: "checkout_abandoned",
    },
    [PaymentMethodEnum.BILLET]: {
      name: "Boleto",
      tag: "billet",
    },
    [PaymentMethodEnum.PIX]: {
      name: "PIX",
      tag: "pix",
    },
    [PaymentMethodEnum.BALANCE]: {
      name: "Saldo",
      tag: "balance",
    },
};