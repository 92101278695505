<template>
    <div class="font-inter flex flex-col gap-8">
        <div>
            <h1 class="text-3xl font-bold pb-2">Pedidos</h1>
            <p class="text-gray-500 text-sm">Adicione, edite e exclua pedidos</p>
        </div>
        <div class="grid grid-cols-12">
            <form @submit.prevent class="rounded-xl w-full relative col-span-12 sm:col-span-9">
                <input type="text" placeholder="Busque pelo nome do pedido" class="rounded-xl input-primary w-full h-full p-4 py-3 bg-f5 pl-11">
                <svg class="absolute top-1/2 -translate-y-1/2 left-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M0.0247006 7.87823C0.162935 9.46532 0.887052 10.9532 2.06688 12.0735C3.24671 13.1938 4.80475 13.8732 6.46033 13.9891C8.11592 14.1051 9.76012 13.6501 11.0972 12.7061L14.2375 15.7092C14.4312 15.8952 14.6938 15.9996 14.9677 15.9996C15.2415 15.9996 15.5043 15.8953 15.6978 15.7093C15.8915 15.5235 16.0002 15.2714 16 15.0085C16.0002 14.7458 15.8915 14.4937 15.698 14.3078L12.5681 11.2947C13.9283 9.5308 14.2998 7.23853 13.5616 5.1613C12.8236 3.08439 11.0702 1.48725 8.86994 0.888013C6.66989 0.288729 4.30337 0.763496 2.53789 2.15874C0.772543 3.55382 -0.16685 5.69131 0.0244658 7.87791L0.0247006 7.87823ZM6.96867 2.62946C8.26895 2.62946 9.51591 3.12511 10.4353 4.00726C11.3547 4.88941 11.8713 6.08607 11.8713 7.33363C11.8713 8.58119 11.3547 9.77768 10.4353 10.6598C9.51591 11.542 8.2689 12.0376 6.96867 12.0376C5.66844 12.0376 4.42124 11.542 3.50184 10.6598C2.58245 9.77768 2.06587 8.58119 2.06587 7.33363C2.06587 6.08607 2.58245 4.88941 3.50184 4.00726C4.42124 3.12511 5.66844 2.62946 6.96867 2.62946Z" fill="#676767"/>
                </svg>
            </form>
        </div>
        <table class="w-full">
            <thead class="hidden sm:grid grid-cols-11 p-4">
                <th class="col-span-1">#ID</th>
                <th class="col-span-1">Total</th>
                <th class="col-span-1">Produto</th>
                <th class="col-span-1">Status</th>
                <th class="col-span-1">Método</th>
                <th class="col-span-2">Organização</th>
                <th class="col-span-2">Cliente</th>
                <th class="col-span-1">Criado em</th>
                <th class="col-span-1">Ações</th>
            </thead>
            <tbody class="flex flex-col gap-4">
                <tr v-for="order in orders" class="justify-between border rounded-xl p-4 grid grid-cols-11">
                    <td class="col-span-5 sm:col-span-1 text-start sm:text-center">{{order.orderId}}</td>
                    <td class="col-span-5 sm:col-span-1 text-start sm:text-center">{{ order.amount?.toLocaleString('pt-BR', { style:'currency', currency:'BRL' }) }}</td>
                    <td class="col-span-5 sm:col-span-1 text-start sm:text-center">{{ order.productTitle }}</td>
                    <td class="col-span-3 sm:col-span-1 text-start sm:text-center">{{paymentStatusDetails[order.statusId].name}}</td>
                    <td class="col-span-5 sm:col-span-1 text-start sm:text-center">{{PaymentMethodDetails[order.methodId].name}}</td>
                    <td class="col-span-11 sm:col-span-2 text-start sm:text-center">
                        <RouterLink :to="{name:'organizations.edit', params: {organizationId: order.organizationId}}" class="hover:text-primary transition-color duration-200">{{ order.organizationName }}</RouterLink>
                    </td>
                    <td class="col-span-11 sm:col-span-2 text-start sm:text-center">{{ order.clientName }}</td>
                    <td class="col-span-6 sm:col-span-1 text-start sm:text-center">{{ new Date(order.createdAt).toLocaleString() }}</td>
                    <td class="flex justify-center gap-4 col-span-5 sm:col-span-1">
                        <div class="flex items-center gap-4">
                            <RouterLink :to="{name:'orders.edit', params:{orderId:order.orderId, organizationId: order.organizationId}}" class="flex gap-4 hover:stroke-primary" @click="page = 'edit'">
                                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path d="M11.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15V13" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M16.5399 3.02001L8.65988 10.9C8.35988 11.2 8.05988 11.79 7.99988 12.22L7.56988 15.23C7.40988 16.32 8.17988 17.08 9.26988 16.93L12.2799 16.5C12.6999 16.44 13.2899 16.14 13.5999 15.84L21.4799 7.96001C22.8399 6.60001 23.4799 5.02001 21.4799 3.02001C19.4799 1.02001 17.8999 1.66001 16.5399 3.02001Z" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M15.4099 4.15002C16.0799 6.54002 17.9499 8.41002 20.3499 9.09002" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg> -->
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary transition-all duration-200" />
                                    <path d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary transition-all duration-200" />
                                </svg>
                            </RouterLink>
                            <!-- <button class="flex gap-4" @click="isDeleteorderModalOpen = true">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button> -->
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <MyModal :height="'18rem'" :width="'28rem'" :isOpen="isDeleteorderModalOpen">
            <div class="p-4 font-inter">
                <button class="transition duration-200 hover:text-primary" @click="isDeleteorderModalOpen = false">
                    <svg viewBox="0 0 24 24" role="presentation" style="width: 1.5rem; height: 1.5rem;"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" style="fill: currentcolor;"></path></svg>
                </button>
                <div class="text-center flex flex-col gap-2">
                    <svg viewBox="0 0 24 24" role="presentation" class="mx-auto" style="width: 2.5rem; height: 2.5rem;"><path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" style="fill: currentcolor;"></path></svg>
                    <h3 class="font-semibold text-2xl">Deletar pedido</h3>
                    <p class="text-sm">Tem certeza que deseja deletar esse pedido? Lembre-se que ele será deletado totalmente de sua lista de pedidos.</p>
                    <div class="flex items-center justify-between pt-6 px-14">
                        <button class="bg-zinc-800 px-8 p-3 rounded-3xl text-white">cancelar</button>
                        <button>sim, deletar</button>
                    </div>
                </div>
            </div>
        </MyModal>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import MyModal from '../../../components/modal/MyModal.vue'
import axiosClient from '@/api/axios';
import { paymentStatusDetails } from '@/enums/PaymentStatusEnum'
import { PaymentMethodDetails } from '@/enums/PaymentMethodEnum'

const isDeleteorderModalOpen = ref<boolean>(false)
const orders = ref<any>([])
const page = ref<any>([])

onMounted(() => {
    axiosClient.get('/administrator/organizations/orders')
    .then((res: any) => {
        console.log(res)
        orders.value = res.data
    })
})
</script>

<style lang="scss">

</style>