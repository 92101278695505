<template>
    <div class="font-inter flex flex-col gap-8">
        <div>
            <h1 class="text-3xl font-bold pb-2 text-292">Organizações</h1>
            <p class="text-gray-500 text-sm">Adicione, edite e exclua organizações</p>
        </div>
        <div class="grid grid-cols-12">
            <form @submit.prevent class="rounded-xl w-full relative col-span-9">
                <input type="text" placeholder="Busque pelo nome da organização" class="rounded-xl input-primary w-full h-full p-4 py-3 bg-f5 pl-11">
                <svg class="absolute top-1/2 -translate-y-1/2 left-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M0.0247006 7.87823C0.162935 9.46532 0.887052 10.9532 2.06688 12.0735C3.24671 13.1938 4.80475 13.8732 6.46033 13.9891C8.11592 14.1051 9.76012 13.6501 11.0972 12.7061L14.2375 15.7092C14.4312 15.8952 14.6938 15.9996 14.9677 15.9996C15.2415 15.9996 15.5043 15.8953 15.6978 15.7093C15.8915 15.5235 16.0002 15.2714 16 15.0085C16.0002 14.7458 15.8915 14.4937 15.698 14.3078L12.5681 11.2947C13.9283 9.5308 14.2998 7.23853 13.5616 5.1613C12.8236 3.08439 11.0702 1.48725 8.86994 0.888013C6.66989 0.288729 4.30337 0.763496 2.53789 2.15874C0.772543 3.55382 -0.16685 5.69131 0.0244658 7.87791L0.0247006 7.87823ZM6.96867 2.62946C8.26895 2.62946 9.51591 3.12511 10.4353 4.00726C11.3547 4.88941 11.8713 6.08607 11.8713 7.33363C11.8713 8.58119 11.3547 9.77768 10.4353 10.6598C9.51591 11.542 8.2689 12.0376 6.96867 12.0376C5.66844 12.0376 4.42124 11.542 3.50184 10.6598C2.58245 9.77768 2.06587 8.58119 2.06587 7.33363C2.06587 6.08607 2.58245 4.88941 3.50184 4.00726C4.42124 3.12511 5.66844 2.62946 6.96867 2.62946Z" fill="#676767"/>
                </svg>
            </form>
        </div>
        <table class="w-full">
            <thead class="grid grid-cols-12 p-4 text-292">
                <th class="col-span-2">Razão Social</th>
                <th class="col-span-2">Nome Fantasia</th>
                <th class="col-span-2">Documento</th>
                <th class="col-span-3">contato</th>
                <th class="col-span-1">Saúde</th>
                <th class="col-span-1">Status</th>
                <th class="col-span-1">Ações</th>
            </thead>
            <tbody class="flex flex-col gap-4 pb-12 mb-10 text-292">
                <tr v-for="(organization, index) in organizations" class="grid grid-cols-12 border rounded-xl p-4">
                    <td class="col-span-2 flex items-center font-bold">
                        <RouterLink :to="{name:'organizations.edit', params: {organizationId: organization.id}}" class="hover:text-primary transition-color duration-200">
                            {{ organization.name }}
                        </RouterLink>
                    </td>
                    <td class="col-span-2">{{ organization.nameFantasy }}</td>
                    <td class="col-span-2 flex items-center justify-center">{{ organization.document }}</td>
                    <td class="col-span-3 flex flex-col justify-center">
                        {{ organization.contactEmail }}
                        <a class="text-greenbase text-underline" :href="`https://wa.me/${organization.contactPhone}`" target="_blank">
                            <u>{{ organization.contactPhone }}</u>
                        </a>
                    </td>
                    <!-- <td class="col-span-2">
                        < <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M6.9 20.6C8.4 21.5 10.2 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 13.8 2.5 15.5 3.3 17L2.44044 20.306C2.24572 21.0549 2.93892 21.7317 3.68299 21.5191L6.9 20.6Z" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16.5 14.8485C16.5 15.0105 16.4639 15.177 16.3873 15.339C16.3107 15.501 16.2116 15.654 16.0809 15.798C15.86 16.041 15.6167 16.2165 15.3418 16.329C15.0714 16.4415 14.7784 16.5 14.4629 16.5C14.0033 16.5 13.512 16.392 12.9937 16.1715C12.4755 15.951 11.9572 15.654 11.4434 15.2805C10.9251 14.9025 10.4339 14.484 9.9652 14.0205C9.501 13.5525 9.08187 13.062 8.70781 12.549C8.33826 12.036 8.04081 11.523 7.82449 11.0145C7.60816 10.5015 7.5 10.011 7.5 9.543C7.5 9.237 7.55408 8.9445 7.66224 8.6745C7.77041 8.4 7.94166 8.148 8.18052 7.923C8.46895 7.6395 8.78443 7.5 9.11793 7.5C9.24412 7.5 9.37031 7.527 9.48297 7.581C9.60015 7.635 9.70381 7.716 9.78493 7.833L10.8305 9.3045C10.9116 9.417 10.9702 9.5205 11.0108 9.6195C11.0513 9.714 11.0739 9.8085 11.0739 9.894C11.0739 10.002 11.0423 10.11 10.9792 10.2135C10.9206 10.317 10.835 10.425 10.7268 10.533L10.3843 10.8885C10.3348 10.938 10.3122 10.9965 10.3122 11.0685C10.3122 11.1045 10.3167 11.136 10.3257 11.172C10.3393 11.208 10.3528 11.235 10.3618 11.262C10.4429 11.4105 10.5826 11.604 10.7809 11.838C10.9837 12.072 11.2 12.3105 11.4344 12.549C11.6778 12.7875 11.9121 13.008 12.151 13.2105C12.3853 13.4085 12.5791 13.5435 12.7323 13.6245C12.7549 13.6335 12.7819 13.647 12.8135 13.6605C12.8495 13.674 12.8856 13.6785 12.9261 13.6785C13.0028 13.6785 13.0613 13.6515 13.1109 13.602L13.4534 13.2645C13.5661 13.152 13.6743 13.0665 13.7779 13.0125C13.8816 12.9495 13.9852 12.918 14.0979 12.918C14.1835 12.918 14.2737 12.936 14.3728 12.9765C14.472 13.017 14.5756 13.0755 14.6883 13.152L16.18 14.2095C16.2972 14.2905 16.3783 14.385 16.4279 14.4975C16.473 14.61 16.5 14.7225 16.5 14.8485Z" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10"/>
                        </svg> -->
                        <!--<a class="text-greenbase text-underline" :href="`https://wa.me/${organization.contactPhone}`" target="_blank">{{ organization.contactPhone }}</a>
                    </td> -->
                    <td class="col-span-1 rounded-xl flex items-center justify-center gap-2 font-semibold">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M2.01562 2V19C2.01562 20.66 3.35563 22 5.01562 22H22.0156" stroke="#05cb95" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5.01562 17L9.60563 11.64C10.3656 10.76 11.7156 10.7 12.5356 11.53L13.4856 12.48C14.3056 13.3 15.6556 13.25 16.4156 12.37L21.0156 7" stroke="#05cb95" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span class="text-success">100%</span>
                    </td>
                    <td class="col-span-1 flex items-center justify-center">{{ organization.status == 'active' ? 'ativo':'inativo' }}</td>
                    <td class="flex items-center justify-center">
                        <RouterLink class="hover:stroke-primary" :to="{name:'organizations.edit', params: {organizationId: organization.id}}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M11.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15V13" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary transition-all duration-200" />
                                <path d="M16.5399 3.02001L8.65988 10.9C8.35988 11.2 8.05988 11.79 7.99988 12.22L7.56988 15.23C7.40988 16.32 8.17988 17.08 9.26988 16.93L12.2799 16.5C12.6999 16.44 13.2899 16.14 13.5999 15.84L21.4799 7.96001C22.8399 6.60001 23.4799 5.02001 21.4799 3.02001C19.4799 1.02001 17.8999 1.66001 16.5399 3.02001Z" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary transition-all duration-200" />
                                <path d="M15.4099 4.15002C16.0799 6.54002 17.9499 8.41002 20.3499 9.09002" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary transition-all duration-200" />
                            </svg>
                        </RouterLink>
                    </td>
                </tr>
            </tbody>
        </table>
        <Pagination/>

        <MyModal :height="'18rem'" :width="'28rem'" :isOpen="isDeleteOrganizationModalOpen">
            <div class="p-4 font-inter">
                <button class="transition duration-200 hover:text-primary" @click="isDeleteOrganizationModalOpen = false">
                    <svg viewBox="0 0 24 24" role="presentation" style="width: 1.5rem; height: 1.5rem;"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" style="fill: currentcolor;"></path></svg>
                </button>
                <div class="text-center flex flex-col gap-2">
                    <svg viewBox="0 0 24 24" role="presentation" class="mx-auto" style="width: 2.5rem; height: 2.5rem;"><path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" style="fill: currentcolor;"></path></svg>
                    <h3 class="font-semibold text-2xl">Deletar organização</h3>
                    <p class="text-sm">Tem certeza que deseja deletar essa organização? Lembre-se que ela será deletada totalmente de sua lista de organizações.</p>
                    <div class="flex items-center justify-between pt-6 px-14">
                        <button class="bg-zinc-800 px-8 p-3 rounded-3xl text-white">cancelar</button>
                        <button>sim, deletar</button>
                    </div>
                </div>
            </div>
        </MyModal>
    </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from 'vue'
import MyModal from '@/components/modal/MyModal.vue'
import axiosClient from '@/api/axios';
import Pagination from '@/components/pagination/Pagination.vue'

const selectedOrganizationActions = ref<number>()
const isDeleteOrganizationModalOpen = ref<boolean>(false)
const organizations = ref<any>([])
const documentOnClick = (e: any) => {
    if(e.target?.id != 'actionsButton' && e.target?.id != 'dropdown'){
        selectedOrganizationActions.value = undefined
    }
}

onMounted(() => {
    axiosClient.get('/administrator/organizations')
    .then((res: any) => {
        organizations.value = res.data
    })

    document.addEventListener('click', documentOnClick)
})

onBeforeUnmount(() => {
    document.removeEventListener('click',documentOnClick);
})

</script>

<style lang="scss">

</style>