<template>
  <RouterView/>
</template>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;
@import './global/scss/app.scss';
</style>
