<template>
    <div class="flex items-center gap-2 pb-10 justify-center pagination">
        <button class="border px-2 py-2 rounded-xl">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M15 19.92L8.48 13.4C7.71 12.63 7.71 11.37 8.48 10.6L15 4.07996" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </button>
        <button class="border px-4 py-2 rounded-xl">1</button>
        <button class="border px-4 py-2 rounded-xl selected">2</button>
        <button class="border px-4 py-2 rounded-xl">3</button>
        <button class="border px-2 py-2 rounded-xl">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M8.91 19.92L15.43 13.4C16.2 12.63 16.2 11.37 15.43 10.6L8.91 4.07996" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </button>
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.pagination{
    button{
        &:hover{
            border-color: var(--primary-color);
            outline:0px solid var(--primary-color)
        }
        &.selected{
            color:var(--primary-color);
            border-color: var(--primary-color);
            outline:1px solid var(--primary-color)
        }
    }
}
</style>