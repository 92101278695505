export enum PaymentStatus {
    Processing = 1,
    Authorized,
    Paid,
    Refunded,
    WaitingPayment,
    PendingRefund,
    Refused,
    Chargedback,
    Analyzing,
    PendingReview,
    CheckoutAbandoned,
    StatusWaiting,
    Recurrency,
    Cancelled,
    Undefined,
    RefundPartial,
    ExpiredPayment,
}
  
  // Define a interface para a estrutura de cada status de pagamento
interface PaymentStatusDetail {
    name: string;
    tag: string;
    color: string;
}
  
  // Define o objeto que mapeia o enum para os detalhes do status de pagamento
export const paymentStatusDetails: { [key in PaymentStatus]: PaymentStatusDetail } = {
    [PaymentStatus.Processing]: {
      name: "Em processamento",
      tag: "processing",
      color: "primary",
    },
    [PaymentStatus.Authorized]: {
      name: "Autorizado",
      tag: "authorized",
      color: "success",
    },
    [PaymentStatus.Paid]: {
      name: "Finalizado",
      tag: "paid",
      color: "success",
    },
    [PaymentStatus.Refunded]: {
      name: "Reembolsado",
      tag: "refunded",
      color: "info",
    },
    [PaymentStatus.WaitingPayment]: {
      name: "Aguardando pagamento",
      tag: "waiting_payment",
      color: "warning",
    },
    [PaymentStatus.PendingRefund]: {
      name: "Reembolso pendente",
      tag: "pending_refund",
      color: "info",
    },
    [PaymentStatus.Refused]: {
      name: "Recusado",
      tag: "refused",
      color: "danger",
    },
    [PaymentStatus.Chargedback]: {
      name: "Chargeback",
      tag: "chargedback",
      color: "danger",
    },
    [PaymentStatus.Analyzing]: {
      name: "Em análise (reembolso)",
      tag: "analyzing",
      color: "info",
    },
    [PaymentStatus.PendingReview]: {
      name: "Revisão pendente",
      tag: "pending_review",
      color: "info",
    },
    [PaymentStatus.CheckoutAbandoned]: {
      name: "Checkout abandonado",
      tag: "checkout_abandoned",
      color: "secondary",
    },
    [PaymentStatus.StatusWaiting]: {
      name: "Aguardando atualização",
      tag: "status_waiting",
      color: "secondary",
    },
    [PaymentStatus.Recurrency]: {
      name: "Recorrência",
      tag: "recurrency",
      color: "success",
    },
    [PaymentStatus.Cancelled]: {
      name: "Cancelado",
      tag: "cancelled",
      color: "danger",
    },
    [PaymentStatus.Undefined]: {
      name: "Aguardando pagamento",
      tag: "undefined",
      color: "warning",
    },
    [PaymentStatus.RefundPartial]: {
      name: "Reembolso Parcial",
      tag: "refund_partial",
      color: "info",
    },
    [PaymentStatus.ExpiredPayment]: {
      name: "Boleto Vencido",
      tag: "expired_payment",
      color: "danger",
    },
};