<template>
    <div>
        <div class="flex justify-between items-center my-4">
            <div>
                <h4 class="font-bold text-2xl text-292">Gerenciar sócios</h4>
                <p class="mb-6 text-94 text-sm">Crie e edite os sócios da organização</p>
            </div>
            <button @click="isCreatePartnersModalOpen = true" class="bg-primary px-4 py-3 text-white flex items-center gap-2 rounded-3xl font-bold">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3.40991 22C3.40991 18.13 7.25991 15 11.9999 15C12.9599 15 13.8899 15.13 14.7599 15.37" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M22 18C22 18.32 21.96 18.63 21.88 18.93C21.79 19.33 21.63 19.72 21.42 20.06C20.73 21.22 19.46 22 18 22C16.97 22 16.04 21.61 15.34 20.97C15.04 20.71 14.78 20.4 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.92 14.43 15.93 15.13 15.21C15.86 14.46 16.88 14 18 14C19.18 14 20.25 14.51 20.97 15.33C21.61 16.04 22 16.98 22 18Z" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M19.49 17.98H16.51" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18 16.52V19.51" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Criar
            </button>
        </div>
        <div v-if="false" class="my-10 py-10">
            <svg xmlns="http://www.w3.org/2000/svg" width="104" height="104" viewBox="0 0 24 24" fill="none" class="mx-auto">
                <path d="M12.0699 8.95005C12.0299 8.95005 11.9699 8.95005 11.9199 8.95005C10.8699 8.91005 10.0399 8.06005 10.0399 7.00005C10.0399 5.92005 10.9099 5.05005 11.9899 5.05005C13.0699 5.05005 13.9399 5.93005 13.9399 7.00005C13.9499 8.06005 13.1199 8.92005 12.0699 8.95005Z" fill="#171717"/>
                <path d="M9.24994 11.9601C7.91994 12.8501 7.91994 14.3001 9.24994 15.1901C10.7599 16.2001 13.2399 16.2001 14.7499 15.1901C16.0799 14.3001 16.0799 12.8501 14.7499 11.9601C13.2399 10.9601 10.7699 10.9601 9.24994 11.9601Z" fill="#171717"/>
                <path opacity="0.4" d="M18 2H6C4.34 2 3 3.33 3 4.97V15.88C3 17.52 4.34 18.85 6 18.85H6.76C7.56 18.85 8.32 19.16 8.88 19.72L10.59 21.41C11.37 22.18 12.64 22.18 13.42 21.41L15.13 19.72C15.69 19.16 16.46 18.85 17.25 18.85H18C19.66 18.85 21 17.52 21 15.88V4.97C21 3.33 19.66 2 18 2ZM12 5.05C13.08 5.05 13.95 5.93 13.95 7C13.95 8.06 13.11 8.91 12.07 8.95C12.03 8.95 11.97 8.95 11.92 8.95C10.87 8.91 10.04 8.06 10.04 7C10.05 5.93 10.92 5.05 12 5.05ZM14.75 15.19C13.24 16.2 10.76 16.2 9.25 15.19C7.92 14.31 7.92 12.85 9.25 11.96C10.77 10.95 13.25 10.95 14.75 11.96C16.08 12.85 16.08 14.3 14.75 15.19Z" fill="#171717"/>
            </svg>
            <h4 class="font-semibold text-292 text-xl text-center">Nenhum sócio cadastrado</h4>
            <p class="text-zinc-400 text-center text-sm">Clique em criar para cadastradar um sócio</p>
        </div>
        <table v-else class="w-full">
            <thead class="grid grid-cols-5 p-4 hidden sm:grid">
                <th class="col-span-1">Nome</th>
                <th class="col-span-1">Documento</th>
                <th class="col-span-1">Data de nascimento</th>
                <th class="col-span-1">Porcentagem</th>
                <th class="col-span-1">Ações</th>
            </thead>
            <tbody class="flex flex-col gap-4">
                <tr class="grid grid-cols-5 border rounded-xl p-4">
                    <td class="col-span-2 sm:col-span-1 flex items-center sm:justify-center">@ João</td>
                    <td class="col-span-2 sm:col-span-1 text-start sm:text-center">@ 92883928392</td>
                    <td class="col-span-2 sm:col-span-1 text-start sm:text-center">@ 01/01/2024</td>
                    <td class="col-span-2 sm:col-span-1 text-start sm:text-center">13%</td>  
                    <td class="col-span-1 sm:col-span-1">
                        <button class="hover:stroke-primary mx-auto" @click="isEditPartnersModalOpen = true">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M11.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15V13" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary transition-all duration-200" />
                                <path d="M16.5399 3.02001L8.65988 10.9C8.35988 11.2 8.05988 11.79 7.99988 12.22L7.56988 15.23C7.40988 16.32 8.17988 17.08 9.26988 16.93L12.2799 16.5C12.6999 16.44 13.2899 16.14 13.5999 15.84L21.4799 7.96001C22.8399 6.60001 23.4799 5.02001 21.4799 3.02001C19.4799 1.02001 17.8999 1.66001 16.5399 3.02001Z" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary transition-all duration-200" />
                                <path d="M15.4099 4.15002C16.0799 6.54002 17.9499 8.41002 20.3499 9.09002" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary transition-all duration-200" />
                            </svg>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <MyModal :height="'28rem'" :isOpen="isCreatePartnersModalOpen" :toggleModal="(v: any) => isCreatePartnersModalOpen = v">
            <div class="p-4 sm:p-8">
                <div class="flex justify-between items-start">
                    <div>
                        <h4 class="font-bold text-2xl">Cadastrar sócio</h4>
                        <p class="mb-6 text-94 text-sm">Campos com (*) são obrigatórios</p>
                    </div>
                    <button class="transition duration-200 hover:text-primary" @click="isCreatePartnersModalOpen = false">
                        <svg viewBox="0 0 24 24" role="presentation" style="width: 1.5rem; height: 1.5rem;"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" style="fill: currentcolor;"></path></svg>
                    </button>
                </div>
                <div class="grid grid-cols-12 gap-6 rounded-xl">
                    <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
                        <label for="" class="font-semibold text-lg input-primary">Nome</label>
                        <input type="text" v-model="partnerForm.name" placeholder="ex: João" class="border border-d5 p-3 rounded-xl w-full input-primary" disabled>
                    </div>
                    <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
                        <label for="" class="font-semibold text-lg input-primary">CPF/CNPJ</label>
                        <input type="text" v-model="partnerForm.document" placeholder="ex: 6078643" class="border border-d5 p-3 rounded-xl w-full input-primary" disabled>
                    </div>
                    <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
                        <label for="" class="font-semibold text-lg input-primary">Data de nascimento</label>
                        <input type="text" v-model="partnerForm.birthdate" placeholder="ex: 08/01/2002" class="border border-d5 p-3 rounded-xl w-full input-primary" disabled>
                    </div>
                    <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
                        <label for="" class="font-semibold text-lg input-primary">Porcentagem</label>
                        <input type="text" v-model="partnerForm.percentage" placeholder="ex: 30" class="border border-d5 p-3 rounded-xl w-full input-primary" disabled>
                    </div>
                </div>
                <button class="bg-primary px-8 py-3 text-white flex items-center gap-2 rounded-3xl font-bold mt-12 w-full justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3.40991 22C3.40991 18.13 7.25991 15 11.9999 15C12.9599 15 13.8899 15.13 14.7599 15.37" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22 18C22 18.32 21.96 18.63 21.88 18.93C21.79 19.33 21.63 19.72 21.42 20.06C20.73 21.22 19.46 22 18 22C16.97 22 16.04 21.61 15.34 20.97C15.04 20.71 14.78 20.4 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.92 14.43 15.93 15.13 15.21C15.86 14.46 16.88 14 18 14C19.18 14 20.25 14.51 20.97 15.33C21.61 16.04 22 16.98 22 18Z" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M19.49 17.98H16.51" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 16.52V19.51" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    Cadastrar sócio
                </button>
            </div>
        </MyModal>
        <MyModal :height="'28rem'" :isOpen="isEditPartnersModalOpen" :toggleModal="(v: any) => isEditPartnersModalOpen = v">
            <div class="p-4 sm:p-8">
                <div class="flex justify-between items-start">
                    <div>
                        <h4 class="font-bold text-2xl">Editar sócio</h4>
                        <p class="mb-6 text-94 text-sm">Campos com (*) são obrigatórios</p>
                    </div>
                    <button class="transition duration-200 hover:text-primary" @click="isEditPartnersModalOpen = false">
                        <svg viewBox="0 0 24 24" role="presentation" style="width: 1.5rem; height: 1.5rem;"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" style="fill: currentcolor;"></path></svg>
                    </button>
                </div>
                <div class="grid grid-cols-12 gap-6 rounded-xl">
                    <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
                        <label for="" class="font-semibold text-lg input-primary">Nome</label>
                        <input type="text" v-model="partnerForm.name" placeholder="ex: João" class="border border-d5 p-3 rounded-xl w-full input-primary" disabled>
                    </div>
                    <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
                        <label for="" class="font-semibold text-lg input-primary">CPF/CNPJ</label>
                        <input type="text" v-model="partnerForm.document" placeholder="ex: 6078643" class="border border-d5 p-3 rounded-xl w-full input-primary" disabled>
                    </div>
                    <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
                        <label for="" class="font-semibold text-lg input-primary">Data de nascimento</label>
                        <input type="text" v-model="partnerForm.birthdate" placeholder="ex: 08/01/2002" class="border border-d5 p-3 rounded-xl w-full input-primary" disabled>
                    </div>
                    <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
                        <label for="" class="font-semibold text-lg input-primary">Porcentagem</label>
                        <input type="text" v-model="partnerForm.percentage" placeholder="ex: 30" class="border border-d5 p-3 rounded-xl w-full input-primary" disabled>
                    </div>
                </div>
                <button class="bg-primary px-8 py-3 text-white flex items-center gap-2 rounded-3xl font-bold mt-12 w-full justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M15.02 3.01001C14.18 2.37001 13.14 2 12 2C9.24 2 7 4.24 7 7C7 9.76 9.24 12 12 12C14.76 12 17 9.76 17 7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M19.2101 15.74L15.67 19.2801C15.53 19.4201 15.4 19.68 15.37 19.87L15.18 21.22C15.11 21.71 15.45 22.05 15.94 21.98L17.29 21.79C17.48 21.76 17.75 21.63 17.88 21.49L21.42 17.95C22.03 17.34 22.32 16.63 21.42 15.73C20.53 14.84 19.8201 15.13 19.2101 15.74Z" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18.7002 16.25C19.0002 17.33 19.8402 18.17 20.9202 18.47" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3.40991 22C3.40991 18.13 7.25994 15 11.9999 15C13.0399 15 14.0399 15.15 14.9699 15.43" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    Salvar alterações
                </button>
            </div>
        </MyModal>
        <div class="box-shadow p-4 flex items-center justify-between rounded-xl my-10">
            <RouterLink :to="{name:'organizations'}" class="flex items-center gap-4 transition-color duration-200 hover:text-primary hover:stroke-primary">
                <div class="bg-d5 p-3 rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="transition-stroke duration-200 hover:parent:stroke-primary stroke-292"><path d="M9.57 5.92969L3.5 11.9997L9.57 18.0697M20.5 11.9997H3.67" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                </div>
                Gerenciar organizações
            </RouterLink>
        </div>
    </div>
</template>

<script setup lang="ts">
import MyModal from '@/components/modal/MyModal.vue';
import { ref } from 'vue'

const partnerForm = ref<any>({})
const isCreatePartnersModalOpen = ref<boolean>(false)
const isEditPartnersModalOpen = ref<boolean>(false)

</script>